<script>
import "@vueform/multiselect/themes/default.css";

import Layout from "../../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../../../app.config";
import Swal from "sweetalert2";
import axios from "axios";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import Lottie from "@/components/widgets/lottie.vue";
import animationData from "@/components/widgets/msoeawqm.json";
import animationData1 from "@/components/widgets/gsqxdxog.json";
import Multiselect from "@vueform/multiselect";
import { ref } from "vue";

export default {
  page: {
    title: "Bot",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  setup() {
    const content = ref("");
    const statusBot = ref(false);

    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })

    return { content, statusBot, Toast };
  },
  data() {
    return {
      title: "Auto Reply",
      items: [
        {
          text: "Auto Reply",
          href: "/",
        },
        {
          text: "First",
          active: true,
        },
      ],
      rangeDateconfig: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: "M j, Y",
        altInput: true,
        dateFormat: "d M, Y",
        mode: "range",
      },
      config: {
        enableTime: false,
        dateFormat: "d M, Y",
      },
      date: null,
      date1: null,
      date2: null,
      filterdate: null,
      filterdate1: null,
      filtervalue: "All",
      filtervalue1: "All",
      filtersearchQuery1: null,
      searchQuery: null,
      page: 1,
      perPage: 9,
      pages: [],
      botList: [],
      botOptionList: [
        {
          ID: 0,
          OptionText: "",
          IsAssign: false,
          ReplyWith: 0,
          Sort: 1,
        },
      ],
      botExclude: [],
      botSelected: [],
      defaultOptions: {
        animationData: animationData,
      },
      defaultOptions1: {
        animationData: animationData1,
      },
      keywordFilter: [],
      keywordFilterOption: [],
    };
  },
  components: {
    Layout,
    PageHeader,
    lottie: Lottie,
    Multiselect,
    QuillEditor,
  },
  computed: {
    displayedPosts() {
      return this.paginate(this.botList);
    },
    resultQuery() {
      if (this.searchQuery) {
        const search = this.searchQuery.toLowerCase();
        return this.displayedPosts.filter((data) => {
          return (
            data.content.toLowerCase().includes(search) ||
            data.FirstChat.toLowerCase().includes(search)
          );
        });
      } else if (this.filterdate !== null) {
        if (this.filterdate !== null) {
          var date1 = this.filterdate.split(" to ")[0];
          var date2 = this.filterdate.split(" to ")[1];
        }
        return this.displayedPosts.filter((data) => {
          if (
            new Date(data.due.slice(0, 12)) >= new Date(date1) &&
            new Date(data.due.slice(0, 12)) <= new Date(date2)
          ) {
            return data;
          } else {
            return null;
          }
        });
      } else if (this.filtervalue !== null) {
        return this.displayedPosts.filter((data) => {
          if (data.status === this.filtervalue || this.filtervalue == "All") {
            return data;
          } else {
            return null;
          }
        });
      } else {
        return this.displayedPosts;
      }
    },
  },
  watch: {
    posts() {
      this.setPages();
    },
  },
  created() {
    this.setPages();
  },
  filters: {
    trimWords(value) {
      return value.split(" ").splice(0, 20).join(" ") + "...";
    },
  },
  async beforeMount() {
    await this.getBotData();
  },
  methods: {
    SearchData() {
      this.searchQuery = this.filtersearchQuery1;
      this.filterdate = this.filterdate1;
      this.filtervalue = this.filtervalue1;
    },
    async getBotData() {
      let url = process.env.VUE_APP_URL_API + "chat-bot/getBot";
      this.botList = [];
      const responseBot = await axios
        .get(url, {
          params: {
            AccessToken: localStorage.getItem("jwt"),
            ClientID: process.env.VUE_APP_CLIENT_ID,
          },
        })
        .catch((err) => {
          return err.data;
        });

      if (responseBot.status === 200) {
        const data = responseBot.data;
        data.Data.forEach((row, idx) => {
          this.botList.push({
            id: row.ID,
            content: row.Content.replace(/<\/?[^>]+(>|$)/g, ""),
            FirstChat: row.First,
            option: [],
          });

          row.Option.forEach((item) => {
            this.botList[idx]["option"].push({
              ID: item.ID,
              OptionText: item.OptionText,
              IsAssign: item.IsAssign === 1 ? true : false,
              ReplyWith: item.ReplyWith,
              Sort: item.Sort,
            });
          });
        });
      }
    },
    editdata(data) {
      this.$router.push(`/apps/auto-reply/edit/${data.id}`)
      document.getElementById("modal-id").style.display = "block";
      document.getElementById("exampleModalLabel").innerHTML = "Edit Bot Chat";

      document.getElementById("botId").value = data.id;
      this.content = data.content;
      this.botOptionList = data.option;

      document.getElementById("idFirstChat").checked = data.FirstChat;

      document.getElementById("edit-btn").style.display = "block";
      document.getElementById("add-btn").style.display = "none";

      this.botExclude = [];
      this.botSelected = [];
      var url = `${process.env.VUE_APP_URL_API}chat-bot/getBotExclude/${data.id}`;
      axios
        .get(url)
        .then((res) => {
          let json = res.data;
          if (json.Meta.Code === 200) {
            this.botSelected = json.Data.Selected;
            json.Data.Option.forEach((row) => {
              this.botExclude.push({
                id: row.ID,
                title: row.Content,
              });
            });
            console.log(this.botSelected);
          } else {
            this.botExclude = [];
          }
        })
        .catch((er) => {
          this.botExclude = [];
          console.log(er);
        });
    },
    addOption() {
      let sort = this.botOptionList.length + 1;
      this.botOptionList.push({
        ID: 0,
        OptionText: "",
        IsAssign: false,
        ReplyWith: 0,
        Sort: sort,
      });
    },
    removeOption(data) {
      let idxData = this.botOptionList.findIndex((el) => {
        return el.Sort === data.Sort;
      });
      if (idxData !== -1) {
        this.botOptionList.splice(idxData, 1);
        this.botOptionList.forEach((item, i) => {
          this.botOptionList[i]["Sort"] = i + 1;
        });
      }
    },
    cancelAddOption() {
      let removeList = this.botOptionList.filter((item) => {
        return item.ID === 0;
      });

      removeList.forEach((item) => {
        var idx = this.botOptionList.findIndex((el) => {
          return el.Sort === item.Sort;
        });

        this.botOptionList.splice(idx, 1);
      });
    },
    loadHtml(delta) {
      this.dataContent = delta;
    },
    updateorder() {
      let result = this.botList.findIndex(
        (o) => o.id == document.getElementById("botId").value
      );

      var id = this.botList[result]["id"];

      var optionData = [];
      this.botOptionList.forEach((row) => {
        optionData.push({
          ID: row.ID,
          OptionText: row.OptionText,
          IsAssign: row.IsAssign,
          ReplyWith: row.ReplyWith,
          Sort: row.Sort,
        });
      });

      var isFirst = false;
      if (this.botList[result]["FirstChat"]) {
        isFirst = true;
      }

      var data = JSON.stringify({
        Content: this.content,
        FirstChat: isFirst,
        KodeMB: JSON.parse(localStorage.getItem("user")).Company.CompanyCode,
        Option: optionData,
      });

      var url = `${process.env.VUE_APP_URL_API
        }chat-bot/updateChatBot/${id}?AccessToken=${localStorage.getItem(
          "jwt"
        )}&ClientID=${process.env.VUE_APP_CLIENT_ID}`;
      axios
        .post(url, data)
        .then(() => {
          console.log("Success updated");
          this.Toast.fire({
            icon: "success",
            title: "Option success updated"
          })
        })
        .catch((er) => {
          this.Toast.fire({
            icon: "error",
            title: "Internal Server Error"
          })
          console.log(er);
        });
      document.getElementById("closemodal").click();
    },
    deletedata(event) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonColor: "#f46a6a",
        confirmButtonColor: "#34c38f",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          let url =
            process.env.VUE_APP_URL_API +
            `chat-bot/deleteBot/${event.id}?AccessToken=${localStorage.getItem(
              "jwt"
            )}&ClientID=${process.env.VUE_APP_CLIENT_ID}`;

          axios
            .get(url)
            .then((res) => {
              var response = res.data;
              if (response.Meta.Code === 200) {
                let idxData = this.botList.findIndex(
                  (el) => el.id === event.id
                );
                this.botList.splice(idxData, 1);
                Swal.fire("Deleted!", response.Meta.Message, "success");
              } else {
                Swal.fire(
                  "Internal Server Error!",
                  response.Meta.Message,
                  "error"
                );
              }
            })
            .catch((er) => {
              console.log(er);
              Swal.fire("Internal Server Error!", er.message, "error");
            });
        }
      });
    },
    deleteMultiple() {
      let ids_array = [];
      var items = document.getElementsByName("chk_child");
      items.forEach(function (ele) {
        if (ele.checked == true) {
          var trNode = ele.parentNode.parentNode.parentNode;
          var id = trNode.querySelector(".id a").innerHTML;
          ids_array.push(id);
        }
      });
      if (typeof ids_array !== "undefined" && ids_array.length > 0) {
        if (confirm("Are you sure you want to delete this?")) {
          var cusList = this.ticketsList;
          ids_array.forEach(function (id) {
            cusList = cusList.filter(function (orders) {
              return orders.id != id;
            });
          });
          this.ticketsList = cusList;
          document.getElementById("checkAll").checked = false;
          var itemss = document.getElementsByName("chk_child");
          itemss.forEach(function (ele) {
            if (ele.checked == true) {
              ele.checked = false;
              ele.closest("tr").classList.remove("table-active");
            }
          });
        } else {
          return false;
        }
      } else {
        Swal.fire({
          title: "Please select at least one checkbox",
          confirmButtonClass: "btn btn-info",
          buttonsStyling: false,
          showCloseButton: true,
        });
      }
    },
    addbot() {
      var optionData = [];
      this.botOptionList.forEach((row) => {
        optionData.push({
          Text: row.OptionText,
          IsAssign: row.IsAssign,
          ReplyWith: row.ReplyWith,
          Sort: row.Sort,
        });
      });

      var data = JSON.stringify({
        Content: this.content,
        FirstChat: document.getElementById("idFirstChat").checked,
        Level: 1,
        KodeMB: JSON.parse(localStorage.getItem("user")).Company.CompanyCode,
        Option: optionData,
      });

      let url = process.env.VUE_APP_URL_API + "chat-bot/createBot";
      url +=
        "?ClientID=" +
        process.env.VUE_APP_CLIENT_ID +
        "&AccessToken=" +
        localStorage.getItem("jwt");
      axios
        .post(url, data)
        .then(async () => {
          await this.getBotData();
          Swal.fire("Success!", "Bot succesfuly created", "success");
        })
        .catch((er) => {
          console.log(er);
          Swal.fire("Fails!", er.message, "error");
        });

      document.getElementById("closemodal").click();
      document.getElementById("addform").reset();
    },
    addnew() {
      this.content = "";
      this.$refs.quilEditor.setContents(this.content);
      this.botOptionList = [
        {
          ID: 0,
          OptionText: "",
          IsAssign: false,
          ReplyWith: 0,
          Sort: 1,
        },
      ];

      document.querySelector('#idFirstChat').setAttribute("checked","checked")
      document.getElementById("addform").reset();
      document.getElementById("modal-id").style.display = "none";
      document.getElementById("exampleModalLabel").innerHTML = "Add Ticket";
      document.getElementById("add-btn").style.display = "block";
      document.getElementById("edit-btn").style.display = "none";

      this.$router.push("/apps/auto-reply/add")
    },
    setPages() {
      let numberOfPages = Math.ceil(this.botList.length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(ticketsList) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return ticketsList.slice(from, to);
    },
    setStatusBot(value) {
      if(value && !this.botList.length){
        this.Toast.fire({
          icon: 'warning',
          text: 'please add bot for enable feature'
        })
        this.statusBot = false;
        document.querySelector("#customSwitchsizemd").checked = this.BotStatus;
      } else {
        let url = `${process.env.VUE_APP_URL_API
          }chat-bot/setBotStatus?Status=${value}&AccessToken=${localStorage.getItem(
            "jwt"
          )}&ClientID=${process.env.VUE_APP_CLIENT_ID}`;
        axios
          .get(url)
          .then((res) => {
            console.log(res);
            this.statusBot = value;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    deselectKeyword(evt){
      let url = `${process.env.VUE_APP_URL_API}chat-bot/deleteKeyword/${evt}`
      axios.get(url, {
        params: {
          ClientID: process.env.VUE_APP_CLIENT_ID,
          AccessToken: localStorage.getItem("jwt")
        }
      }).then(res => {
        if(res.data.Meta.Code == 200){
          this.initKeywordOption();
        }
      }).catch(err => {
        console.log(err);
      })
    },
    selectKeyword(option){
      let url = `${process.env.VUE_APP_URL_API}chat-bot/addKeyword`
      var formData = new FormData();
      formData.append("Word", option.value);
      
      axios.post(url, formData, {
        params: {
          ClientID: process.env.VUE_APP_CLIENT_ID,
          AccessToken: localStorage.getItem("jwt")
        }
      }).then(res => {
        if(res.data.Meta.Code == 200){
          this.keywordFilter = [];
          this.initKeywordOption();
        }
      }).catch(err => {
        console.log(err);
      })
      option = {
        value: '',
        label: ''
      }
      return option;
    },
    initKeywordOption(){
      let url = `${process.env.VUE_APP_URL_API}chat-bot/getKeyword`
      axios.get(url, {
        params: {
          ClientID: process.env.VUE_APP_CLIENT_ID,
          AccessToken: localStorage.getItem("jwt")
        }
      }).then(res => {
        if (res.data.Meta.Code == 200) {
          this.keywordFilter = [];
          this.keywordFilterOption = [];
          res.data.Data.forEach(item => {
            this.keywordFilter.push(item.ID);
            this.keywordFilterOption.push({
              value: item.ID,
              label: item.Word
            })
          })
        }
      }).catch(err => {
        console.log(err);
      })
    }
  },
  mounted() {
    var checkAll = document.getElementById("checkAll");
    if (checkAll) {
      checkAll.onclick = function () {
        var checkboxes = document.querySelectorAll(
          '.form-check-all input[type="checkbox"]'
        );
        if (checkAll.checked == true) {
          checkboxes.forEach(function (checkbox) {
            checkbox.checked = true;
            checkbox.closest("tr").classList.add("table-active");
          });
        } else {
          checkboxes.forEach(function (checkbox) {
            checkbox.checked = false;
            checkbox.closest("tr").classList.remove("table-active");
          });
        }
      };
    }

    if(sessionStorage.getItem("botLevel")){
      sessionStorage.removeItem("botLevel");
    }

    let company = JSON.parse(localStorage.getItem("userdata")).Company;
    this.statusBot = company.BotStatus == 0 ? false : true;

    this.initKeywordOption();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-xl-12 col-lg-12">
        <div class="card">
          <div class="card-header">
            <div class="d-flex mb-3">
              <div class="flex-grow-1">
                <h5 class="fs-16">Keyword</h5>
              </div>
              <div class="flex-shrink-0">
                <a href="#" class="text-decoration-underline">Clear All</a>
              </div>
            </div>
          </div>

          <div class="accordion accordion-flush">
            <div class="card-body border-bottom">
              <div class="alert alert-secondary shadow" role="alert">
                  Keyword digunakan ketika customer memulai obrolan dan kalimat mengandung kata di bawah ini maka akan otomatis ter assign ke agent
              </div>
              <Multiselect ref="keywordTag" v-model="keywordFilter" :on-create="selectKeyword" v-on:deselect="deselectKeyword($event)" mode="tags" :close-on-select="false" :searchable="true" :create-option="true"
                :options="keywordFilterOption" />
            </div>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card" id="ticketsList">
          <div class="card-header border-0">
            <div class="d-flex align-items-center">
              <h5 class="card-title mb-0 flex-grow-1">Auto Reply</h5>
            </div>
          </div>
          <div class="card-body border border-dashed border-end-0 border-start-0">
            <div class="row">
              <div class="col-6">
                <div class="row">
                  <label class="form-check-label" for="customSwitchsizemd">Bot Status</label>
                </div>
                <div class="form-check form-switch form-switch-md" dir="ltr">
                  <input @change="(e) => {
                      setStatusBot(e.target.checked);
                    }
                    " :checked="statusBot" type="checkbox" class="form-check-input" id="customSwitchsizemd" />
                </div>
              </div>
              <div class="col-6 text-end">
                <!-- <button v-if="!resultQuery.length" class="btn btn-danger add-btn" data-bs-toggle="modal" data-bs-target="#showModal"
                  @click="addnew">
                  <i class="ri-add-line align-bottom"></i> Add
                </button> -->
                <button v-if="!resultQuery.length" class="btn btn-danger add-btn" @click="addnew"><i class="ri-add-line align-bottom"></i> Add</button>
              </div>
            </div>
          </div>
          <!--end card-body-->
          <div class="card-body">
            <div class="table-responsive table-card mb-4">
              <table class="table align-middle table-nowrap mb-0" id="ticketTable">
                <thead>
                  <tr>
                    <th class="sort" data-sort="id">ID</th>
                    <th class="sort" data-sort="tasks_name">Content</th>
                    <!-- <th class="sort" data-sort="client_name">FirstChat</th> -->
                    <th class="sort" data-sort="first_chat">Option</th>
                    <th class="sort text-center" data-sort="assignedto">Opsi</th>
                  </tr>
                </thead>
                <tbody class="list form-check-all">
                  <tr v-for="(data, index) of resultQuery" :key="index">
                    <td class="id">
                      <router-link to="/apps/tickets-details" class="fw-medium link-primary">{{ data.id }}</router-link>
                    </td>
                    <td class="tasks_name">
                      {{ data.content }}
                    </td>
                    <!-- <td class="first_chat">
                      <span class="badge text-uppercase" :class="{
                        'badge-soft-success': data.FirstChat == true,
                        'badge-soft-info': data.FirstChat == false,
                      }">{{ data.FirstChat }}</span>
                    </td> -->
                    <td class="client_name">
                      <ol>
                        <li v-for="rows in data.option" :key="rows.ID">
                          {{ rows.OptionText }}
                        </li>
                      </ol>
                    </td>
                    <td class="text-center">
                      <div class="dropdown">
                        <!-- <router-link :to="`/apps/auto-reply/detail/${data.id}`" class="btn btn-soft-success btn-sm me-2"
                          type="button">
                          <i class="ri-eye-fill align-middle"></i>
                        </router-link> -->
                        <a class="btn btn-soft-warning btn-sm edit-item-btn me-2"
                          @click="editdata(data)">
                          <i class="ri-pencil-fill align-middle"></i>
                        </a>
                        <a class="btn btn-soft-danger btn-sm remove-item-btn" @click="deletedata(data)">
                          <i class="ri-delete-bin-fill align-middle"></i>
                        </a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="noresult" style="display: none" :class="{ 'd-block': resultQuery.length == 0 }">
                <div class="text-center">
                  <lottie class="avatar-xl" colors="primary:#121331,secondary:#08a88a" :options="defaultOptions"
                    :height="90" :width="90" />
                  <h5 class="mt-2">Sorry! No Result Found</h5>
                  <p class="text-muted mb-0">
                    Data chat automation not found !!
                  </p>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-end mt-3">
              <div class="pagination-wrap hstack gap-2">
                <a class="page-item pagination-prev disabled" href="#" v-if="page != 1" @click="page--">
                  Previous
                </a>
                <ul class="pagination listjs-pagination mb-0">
                  <li :class="{
                    active: pageNumber == page,
                    disabled: pageNumber == '...',
                  }" v-for="(pageNumber, index) in pages.slice(
                    page - 1,
                    page + 5
                  )" :key="index" @click="page = pageNumber">
                    <a class="page" href="#">{{ pageNumber }}</a>
                  </li>
                </ul>
                <a class="page-item pagination-next" href="#" @click="page++" v-if="page < pages.length">
                  Next
                </a>
              </div>
            </div>

            <!-- Modal -->
            <div class="modal fade flip" id="deleteOrder" tabindex="-1" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-body p-5 text-center">
                    <lottie class="avatar-xl" colors="primary:#4b38b3,secondary:#f06548" :options="defaultOptions1"
                      :height="90" :width="90" />
                    <div class="mt-4 text-center">
                      <h4>You are about to delete a order ?</h4>
                      <p class="text-muted fs-14 mb-4">
                        Deleting your order will remove all of your information
                        from our database.
                      </p>
                      <div class="hstack gap-2 justify-content-center remove">
                        <button class="btn btn-link link-success fw-medium text-decoration-none" data-bs-dismiss="modal">
                          <i class="ri-close-line me-1 align-middle"></i> Close
                        </button>
                        <button class="btn btn-danger" id="delete-record">
                          Yes, Delete It
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--end modal -->
          </div>
          <!--end card-body-->
        </div>
        <!--end card-->
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <!-- Model edit -->
    <div class="modal fade zoomIn" id="showModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content border-0">
          <div class="modal-header p-3 bg-soft-info">
            <h5 class="modal-title" id="exampleModalLabel"></h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal"></button>
          </div>
          <form id="addform">
            <div class="modal-body">
              <div class="row g-3">
                <div class="col-lg-12">
                  <div id="modal-id">
                    <label for="orderId" class="form-label">ID</label>
                    <input type="text" id="botId" class="form-control" placeholder="ID" value="" readonly />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div>
                    <label for="tasksTitle-field" class="form-label">Content</label>
                    <QuillEditor v-model:content="content" @update:content="loadHtml(content)" theme="snow"
                      :toolbar="['bold', 'italic', 'strike']" contentType="html" placeholder="Input here"
                      ref="quilEditor"></QuillEditor>
                  </div>
                </div>
                <div class="col-lg-12">
                  <!-- Switch sizes -->
                  <div class="form-check form-switch" dir="ltr">
                    <input type="checkbox" class="form-check-input" id="idFirstChat" />
                    <label class="form-check-label" for="idFirstChat">Is First Chat</label>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-lg-1 text-center" style="margin-right: -20px">
                    <label for="ticket-status" class="form-label">Sort</label>
                  </div>
                  <div class="col-lg-5">
                    <label for="ticket-status" class="form-label">Option</label>
                  </div>
                  <div class="col-lg-2">
                    <label for="priority-field" class="form-label">Is Assign</label>
                  </div>
                  <div class="col-lg-1"></div>
                </div>
                <div class="row mb-2" v-for="(opt, idx) in this.botOptionList" :key="opt.ID">
                  <div class="col-lg-1 mt-2" style="margin-right: -20px">
                    <p class="text-center">{{ opt.Sort }}</p>
                  </div>
                  <div class="col-lg-5">
                    <input v-model="opt.OptionText" type="text" class="form-control" placeholder="Option Text" />
                  </div>
                  <div class="col-lg-2 mt-2">
                    <input v-model="opt.IsAssign" class="form-check-input" type="checkbox" :id="'formCheck-' + idx" />
                    Assign
                  </div>
                  <div class="col-lg-1">
                    <button v-if="idx > 0 && !opt.IsAssign" @click="removeOption(opt)" type="button"
                      class="btn btn-danger btn-sm btn-icon waves-effect waves-light">
                      <i class="ri-delete-bin-5-line"></i>
                    </button>
                  </div>
                </div>
                <button type="button" class="btn btn-primary" @click="addOption">
                  Add Option
                </button>
              </div>
            </div>
            <div class="modal-footer">
              <div class="hstack gap-2 justify-content-end">
                <button type="button" class="btn btn-light" data-bs-dismiss="modal" @click="cancelAddOption"
                  id="closemodal">
                  Close
                </button>
                <button type="button" class="btn btn-success" id="add-btn" @click="addbot">
                  Add Bot
                </button>
                <button type="button" class="btn btn-success" id="edit-btn" @click="updateorder">
                  Update
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </Layout>
</template>
